function logEvent(name, params) {
    console.log(`LogEvent llamado con nombre: ${name}, params: ${JSON.stringify(params)}`);
    if (!name) {
        return;
    }

    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
    } else if (window.webkit
        && window.webkit.messageHandlers
        && window.webkit.messageHandlers.firebase) {
        // Call iOS interface
        var message = {
            command: 'logEvent',
            name: name,
            parameters: params
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log("No native APIs found.");
    }
}

function setUserProperty(name, value) {
    if (!name || !value) {
        return;
    }

    if (window.AnalyticsWebInterface) {
        // Call Android interface
        window.AnalyticsWebInterface.setUserProperty(name, value);
    } else if (window.webkit
        && window.webkit.messageHandlers
        && window.webkit.messageHandlers.firebase) {
        // Call iOS interface
        var message = {
            command: 'setUserProperty',
            name: name,
            value: value
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
        // No Android or iOS interface found
        console.log("No native APIs found.");
    }
}

function sendScreenView(pantalla) {
    const message = {
        event: 'screen_view',
        screen_name: pantalla
    };
    try {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } catch (error) {
        if (window.ReactNativeWebView == undefined) {
            console.log('ReactNativeWebView is undefined');
        } else {
            console.error(error);
        }
    }
}

function sendUserID(user_id) {
    const message = {
        event: 'user_id',
        user_id: user_id
    };
    try {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } catch (error) {
        if (window.ReactNativeWebView == undefined) {
            console.log('ReactNativeWebView is undefined');
        } else {
            console.error(error);
        }
    }
}

function sendUserproperties(user_properties) {
    const message = {
        event: 'user_properties',
        user_properties: user_properties
    };
    try {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } catch (error) {
        if (window.ReactNativeWebView == undefined) {
            console.log('ReactNativeWebView is undefined');
        } else {
            console.error(error);
        }
    }
}